import React from "react"
import styled from "styled-components"
import { color, fontSize, shadows } from "../../theme"
import Map from "../../images/press/map.svg"

export default function Hero() {
  return <Wrapper></Wrapper>
}

const Wrapper = styled.div`
  .title {
    font-size: ${fontSize.h1};
    font-weight: 700;
    margin-bottom: 48px;
    color: ${color.black87};
    @media (max-width: 670px) {
      font-size: ${fontSize.h3};
      margin-bottom: 20px;
    }
  }
  .hero {
    background: #d8f2f69e;
    border-radius: 16px;
    width: 100%;
    padding: 17px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 80px;

    @media (max-width: 670px) {
      height: unset;
      padding: 15px 20px;
    }

    .hero_text {
      font-size: ${fontSize.h5};
      color: ${color.black87};
      line-height: 24px;
      a {
        color: ${color.tangerine};
        font-size: ${fontSize.body};
        text-decoration: none;
      }
    }
    .hero_image {
      margin-left: 20px;
      align-self: center;
      img {
        width: 132px;
        @media (max-width: 670px) {
          width: 70px;
        }
      }
    }
  }
`
