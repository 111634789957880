import React from "react"
import styled from "styled-components"
import { color, fontSize, shadows } from "../../theme"
import business_insider from "../../images/press/business_insider.svg"
import lonely_planet from "../../images/press/lonely_planet.svg"
import bbc from "../../images/press/bbc.svg"
import bbc_vid from "../../images/press/bbc_vid.png"
import ted from "../../images/press/ted.png"
import ted_vid from "../../images/press/ted_vid.png"
import money from "../../images/press/money.png"
import foxnews from "../../images/press/foxnews.png"
import cryptoren from "../../images/press/cryptoren.png"
import brisbane_airport from "../../images/press/brisbane_airport.svg"
import tbb from "../../images/press/tbb.png"

const MEDIA = [
  {
    date: "12 February, 2018",
    title:
      "TravelbyBit: A Travel Booking Website That Lets Users Pay With Cryptocurrency and Earn Crypto Rewards",
    logo: tbb,
    link:
      "https://www.cardrates.com/news/travelbybit-facilitates-crypto-travel-booking-and-rewards/",
  },
  {
    date: "9 December, 2018",
    title: "Caleb Yeoh at TEDxBrisbane 2018",
    logo: ted,
    link: "https://vimeo.com/305418242",
    image: ted_vid,
  },
  {
    date: "3 October, 2018",
    title:
      "Queensland blockchain startup TravelbyBit just won a $3.5 million investment from global cryptocurrency exchange Binance",
    logo: business_insider,
    link:
      "https://www.businessinsider.com.au/queensland-blockchain-startup-cryptocurrency-exchange-binance-2018-10",
  },
  {
    date: "16 September, 2018",
    title: "Cryptocurrency for travellers",
    logo: bbc,
    link: "https://vimeo.com/292020954",
    image: bbc_vid,
  },
  {
    date: "22 February, 2018",
    title:
      "80-year-old Singaporean bitcoin investor goes on cryptocurrency-only vacation in Australia, leaves all cash and cards behind",
    logo: cryptoren,
    link:
      "https://cryptoren.com/bitcoin-investor-cryptocurrency-only-vacation-australia/",
  },
  {
    date: "2 February, 2018",
    title: "Have Bitcoin? This airport is starting to accept cryptocurrency",
    logo: lonely_planet,
    link:
      "https://www.lonelyplanet.com/news/2018/02/01/brisbane-airport-bitcoin-cryptocurrency/",
  },
  {
    date: "30 January, 2018",
    title:
      "This Major Airport Will Be the First in the World to Accept Bitcoin",
    logo: money,
    link:
      "http://money.com/money/5125368/this-major-airport-will-be-the-first-in-the-world-to-accept-bitcoin/",
  },
  {
    date: "30 January, 2018",
    title: "Major airport announces it will now accept bitcoin",
    logo: foxnews,
    link:
      "http://www.foxnews.com/travel/2018/01/30/major-airport-announces-it-will-now-accept-bitcoin.html",
  },

  {
    date: "24 January, 2018",
    title:
      "Brisbane Airport and TravelbyBit launch world-first digital currency airport terminal",
    logo: brisbane_airport,
    link:
      "https://newsroom.bne.com.au/brisbane-airport-and-travelbybit-launch-world-first-digital-currency-airport-terminal/",
  },
]

export default function Medias() {
  return (
    <Wrapper>
      {MEDIA.map((item, key) => (
        <MideaBlock key={key}>
          {item.image && (
            <a href={item.link} target="_blank">
              <img src={item.image} className="vid_image" alt="video" />
            </a>
          )}
          <div className="des">
            <div className="date">{item.date}</div>
            <div className="title">
              <a href={item.link} target="_blank">
                {item.title}
              </a>
            </div>
          </div>
          <img src={item.logo} alt="logo" className="logo" />
        </MideaBlock>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: 30px;
  width: 100%;
  min-width: 620px;
  @media (max-width: 1035px) {
    margin-top: 130px;
  }
  @media (max-width: 670px) {
    min-width: unset;
  }
`

const MideaBlock = styled.div`
  width: 100%;
  padding: 20px 0;
  border-top: 2px solid ${color.bgGrey};
  display: flex;
  flex-direction: row;
  align-items: center;
  &:last-child {
    border-bottom: 2px solid ${color.bgGrey};
  }

  .vid_image {
    width: 144px;
    margin-right: 20px;
    @media (max-width: 600px) {
      width: 84px;
    }
  }
  .des {
    flex-grow: 1;

    .date {
      font-size: ${fontSize.small};
      color: ${color.black60};
      margin-bottom: 5px;
      @media (max-width: 600px) {
        font-size: ${fontSize.tiny};
      }
    }
    .title a {
      font-size: ${fontSize.body};
      color: ${color.black87};
      font-weight: 600;
      text-decoration: none;
      &:hover {
        color: ${color.tangerine};
      }
      @media (max-width: 600px) {
        font-size: ${fontSize.small};
      }
    }
  }
  .logo {
    width: 80px;
    margin-left: 20px;
    @media (max-width: 600px) {
      width: 62px;
    }
  }
`
