import React from "react"
import styled, { css } from "styled-components"
import { color, fontSize, shadows } from "../../theme"

const AWARDS = [
  {
    name: "WINNER - FINNIE AWARDS 2018",
    des: "Excellence in industry collaboration and partnerships",
    link:
      "https://www.smartcompany.com.au/startupsmart/news/afterpay-airwallex-and-civic-ledger-among-the-aussie-fintech-startups-celebrated-at-the-finnie-awards-2018/",
  },
  {
    name: "Finalist - Premier of Queensland’s Export Awards 2019",
    des: "Dermot McManus Award for Innovation",
    link:
      "http://statements.qld.gov.au/Statement/2019/9/9/finalists-announced-in-premier-of-queenslands-export-awards-2019",
  },
  {
    name: "Finalist - Lord Mayor’s Business Awards 2018",
    des: "Australia Pacific LNG Award for Business innovation",
    link:
      "http://www.choosebrisbane.com.au/corporate/lmba/winners-and-finalists/2018-lmba-finalists?sc_lang=en-au",
  },
]

export default function SideBar(props) {
  return (
    <Wrapper mobile={props.mobile}>
      <div className="title">Awards / Nominations</div>
      {AWARDS.map((item, key) => (
        <Awards>
          <div className="name">{item.name}</div>
          <div className="des">
            <a href={item.link} target="_blank">
              {item.des}
            </a>
          </div>
        </Awards>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  min-width: 360px;
  position: relative;
  @media (max-width: 1110px) {
    min-width: unset;
  }

  ${props =>
    props.mobile
      ? css`
          margin-top: 130px;
        `
      : css`
          padding: 72px 0 0 40px;
          background-color: ${color.bgGrey};
          @media (max-width: 1035px) {
            display: none;
          }
        `}

  .title {
    color: ${color.black87};
    font-size: ${fontSize.h3};
    margin-bottom: 50px;
    font-weight: 700;
    ${props =>
      props.mobile &&
      css`
        display: none;
      `}
  }
  a {
    text-decoration: none;
  }
`

const Awards = styled.div`
  margin: 40px 0;

  .name {
    color: ${color.black60};
    font-size: ${fontSize.small};
    margin-bottom: 7px;
    font-weight: 300;
    letter-spacing: 0.7px;
    text-transform: uppercase;
  }
  .des a {
    color: ${color.black87};
    font-size: ${fontSize.body};
    font-weight: 700;
    &:hover {
      color: ${color.tangerine};
    }
  }
`
