import React from "react"
import styled from "styled-components"
import { color, fontSize, shadows } from "../theme"
import SEO from "../components/seo"
import Hero from "../components/press/Hero"
import SideBar from "../components/press/SideBar"
import Medias from "../components/press/Medias"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"

export default function Press({ data }) {
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <>
      <SEO title="Press" />
      <Page>
        <div className="container">
          <Main>
            <Hero />
            <StyledTabs>
              <Tabs value={value} onChange={handleChange}>
                <Tab label="Press" />
                <Tab label="Awards / Nominations" />
              </Tabs>
            </StyledTabs>
            {value === 0 && <Medias />}
            {value === 1 && <SideBar mobile={true} />}
            <Contact>
              <div className="title">Contact</div>
              <div className="email">
                For media inquiries, please contact us at{" "}
                <a href="mailto:press@travelbybit.com">press@travelbybit.com</a>
              </div>
            </Contact>
          </Main>
          <SideBar mobile={false} />
        </div>
      </Page>
    </>
  )
}

const Page = styled.div`
  background: linear-gradient(90deg, ${color.white} 50%, ${color.bgGrey} 50%);
  @media (max-width: 1035px) {
    background: ${color.white};
  }
  .container {
    width: 100%;
    margin: 0 auto;
    max-width: 1170px;
    display: flex;
    flex-direction: row;
    margin-top: -28px;

    @media (max-width: 1245px) {
      max-width: unset;
      padding: 0 30px;
    }
    @media (max-width: 1035px) {
      padding: 0;
      margin-top: 50px;
    }
  }
`

const Main = styled.div`
  width: 100%;
  background: ${color.white};
  box-shadow: 5px 0px 6px 0px rgba(0, 0, 0, 0.06);
  padding: 72px 40px 0 0;
  z-index: 1;
  max-width: 815px;

  @media (max-width: 1035px) {
    margin: 0 auto;
    box-shadow: unset;
    padding: 0 20px;
  }
`

const StyledTabs = styled.div`
  @media (min-width: 1036px) {
    display: none;
  }
  @media (max-width: 1035px) {
    margin-top: 30px;
    position: absolute;
    width: 100%;
    left: 0;
    box-shadow: 1px 12px 15px 0px rgba(204, 204, 204, 0.32);
  }
  .MuiTabs-root {
    max-width: 815px;
    padding: 0 20px;
    margin: 0 auto;
    button.MuiTab-root {
      min-width: unset !important;
      margin-right: 10px;
      color: ${color.black60};
      &.Mui-selected {
        color: ${color.tangerine};
      }
    }
    .MuiTabs-indicator {
      background-color: ${color.tangerine};
      height: 3px;
    }
  }
`

const Contact = styled.div`
  margin: 70px 0;
  .title {
    font-size: ${fontSize.h3};
    font-weight: 600;
    color: ${color.black87};
    margin-bottom: 10px;
  }
  .email {
    font-size: ${fontSize.body};
    color: ${color.black87};
    a {
      text-decoration: none;
      color: ${color.tangerine};
      font-weight: 600;
    }
  }
`
